<template>
  <!-- search input --->
  <div class="flex items-center mb-4 gap-3 w-full">
    <searchbar
      :id="'search'"
      :label="$t('search')"
      v-model="state.search"
      :labelClass="'bg-white-dark'"
      :activeColor="'primary'"
      class="w-full sm:w-1/3 "
      :placeHolder="$t('search')"
    />
  </div>

  <!-- Content -->
  <div v-if="filteredtags != null && filteredtags.length > 0">
    <div
      class="bg-white cursor-pointer  rounded-md h-auto flex-1 gap-8  mb-3 hover:border-grey-fontgrey"
      :class="
        GET_CUSTOMER_CHANNEL.id == channel.id
          ? 'border-t-4 border-primary'
          : 'border border-transparent'
      "
      v-for="channel of filteredtags"
      :key="channel.id"
      :channel="channel"
      @click="edit_channel(channel)"
    >
      <channel-container
        :editContainer="true"
        v-if="GET_CUSTOMER_CHANNEL != null"
        :channel="channel"
        @editChannel="edit_channel(channel)"
      />
    </div>
  </div>
  <!--empty feedback-->
  <div v-else>
    <empty-list-feedback
      class="pt-10 flex justify-center items-center"
      v-if="filteredtags == null || filteredtags.length == 0"
      :text="$t('no_channel')"
    ></empty-list-feedback>
  </div>

  <!--edit channel modal-->
  <edit-channel-modal :isVisible="state.openModal" @closeModal="set_back()" />
</template>

<script>
import { computed, defineComponent, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import {
  AUTH_TYPES,
  CUSTOMER_CHANNELS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import ChannelContainer from "../components/ChannelContainer.vue";
import EditChannelModal from "../modals/EditChannelModal.vue";

export default defineComponent({
  components: {
    Searchbar,
    ChannelContainer,
    EditChannelModal,
    EmptyListFeedback,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    /** State */
    const state = reactive({
      openModal: false,
    });

    /*
     * Computeds
     */
    const GET_CUSTOMER_CHANNELS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_CHANNELS}/${CUSTOMER_CHANNELS_TYPES.GET_CUSTOMER_CHANNELS}`
      ];
    });
    const GET_CUSTOMER_CHANNEL = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_CHANNELS}/${CUSTOMER_CHANNELS_TYPES.GET_CUSTOMER_CHANNEL}`
      ];
    });
    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    const filteredtags = computed(() => {
      if (GET_CUSTOMER_CHANNELS.value == null) {
        return;
      }
      return GET_CUSTOMER_CHANNELS.value.filter((search) => {
        if (state.search == "" || state.search == null) {
          return true;
        }
        if (search.name != null) {
          return search.name.toLowerCase().match(state.search.toLowerCase());
        }
      });
    });

    /*
     * Functions
     */
    const edit_channel = (channel) => {
      SET_CUSTOMER_CHANNEL(channel);
      state.openModal = true;
    };
    const set_back = () => {
      SET_CUSTOMER_CHANNEL({});
      state.openModal = false;
    };

    const SET_CUSTOMER_CHANNEL = (channel) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_CHANNELS}/${CUSTOMER_CHANNELS_TYPES.SET_CUSTOMER_CHANNEL}`,
        channel
      );
    };

    function FETCH_CUSTOMER_CHANNELS() {
      return store.dispatch(
        `${STORE_TYPES.CUSTOMER_CHANNELS}/${CUSTOMER_CHANNELS_TYPES.FETCH_CUSTOMER_CHANNELS}`,
        GET_USER.value.customer_id
      );
    }

    watch(
      () => GET_USER.value,
      (newValue) => {
        if (newValue != null && newValue.customer_id != null) {
          FETCH_CUSTOMER_CHANNELS(newValue.customer_id);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      store,
      state,
      route,
      edit_channel,
      set_back,
      filteredtags,
      GET_CUSTOMER_CHANNELS,
      GET_CUSTOMER_CHANNEL,
    };
  },
});
</script>
