<template>
  <main class="flex flex-col items-center">
    <main-content-container>
      <h2 class="font-semibold mb-6 mt-3 font-roboto text-xlg text-black">
        {{ $t("channel") }}
      </h2>

      <channel />
    </main-content-container>
  </main>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import MainContentContainer from "../../components/global/MainContentContainer.vue";
import Channel from "./show/Channel.vue";

export default defineComponent({
  components: {
    MainContentContainer,
    Channel,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    /*
     * State
     */

    const state = reactive({});

    /*
     *Computeds
     */

    /*
     *Functions
     */

    return { state, route, store };
  },
});
</script>
