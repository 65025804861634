<template>
  <div class="p-3" v-if="channel != null">
    <!-- name -->
    <div class="flex items-center gap-2">
      <div class="font-semibold flex gap-2">
        <div>{{ channel.name }}</div>
      </div>

      <div
        class="ml-auto rounded-full w-10 h-10 items-center flex hover:bg-primary-lightest "
        @click.stop="$emit('editChannel')"
      >
        <div class="cursor-pointer ml-2"><edit-icon /></div>
      </div>
    </div>
    <!----- info----- -->

    <div class="mt-3" v-if="editContainer">
      <div class="text-sm text-blue">
        {{ $t("device_id") }}
      </div>
      <div class="text-md">
        {{ channel.network_address }}
      </div>
    </div>
    <div class="text-grey-fontgrey text-sm" v-if="!editContainer">
      {{ $t("device_id") }}: {{ channel.network_address }}
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import EditIcon from "../../../components/icons/EditIcon.vue";
import { CUSTOMER_CHANNELS_TYPES, STORE_TYPES } from "../../../store/types";

export default defineComponent({
  components: { EditIcon },
  emits: ["editChannel"],

  name: "Channel",
  props: {
    editContainer: {
      type: Boolean,
      default: false,
      required: false,
    },
    channel: {
      type: Object,
    },
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    /** State */
    const GET_CUSTOMER_CHANNEL = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_CHANNELS}/${CUSTOMER_CHANNELS_TYPES.GET_CUSTOMER_CHANNEL}`
      ];
    });

    return {
      route,
      store,
      GET_CUSTOMER_CHANNEL,
    };
  },
});
</script>
