<template>
  <bottom-up-modal
    :isVisible="isVisible"
    @closeModal="$emit('closeModal')"
    :title="$t('edit_channel')"
  >
    <div v-if="GET_CUSTOMER_CHANNEL != null">
      <!-- receiver form -->
      <div class="flex w-full flex-wrap sm:flex-nowrap gap-2">
        <input-text
          class="w-full sm:w-1/3"
          :id="'channel_name'"
          :label="$t('designation')"
          :name="'name'"
          :labelClass="'bg-white'"
          v-model="GET_CUSTOMER_CHANNEL.name"
        />
        <input-text
          class="w-full sm:w-1/3"
          :id="'channel_id'"
          :label="$t('device_id')"
          :name="'network_address'"
          :labelClass="'bg-white'"
          v-model="GET_CUSTOMER_CHANNEL.network_address"
        />
      </div>
      <!-- action buttons -->
      <div class="mt-5 flex gap-4 ">
        <custom-button
          class="h-11 w-full sm:w-1/4 ml-auto"
          :bgColor="'bg-grey-fontgrey'"
          :textColor="'text-white'"
          :text="$t('abort')"
          @click="$emit('closeModal')"
        />
        <custom-button
          class="h-11 w-full sm:w-1/4"
          :bgColor="'bg-primary'"
          :textColor="'text-white'"
          :text="$t('edit_2')"
          :id="'update'"
          @click="update_channel(GET_CUSTOMER_CHANNEL)"
        />
      </div>
    </div>

    <div class="w-full h-px bg-primary-light mt-8"></div>

    <div class="font-semibold mt-8">{{ $t("channel") }}</div>
    <div class="flex items-center mt-4 mb-3">
      <searchbar
        :label="$t('designation')"
        :labelClass="'bg-white'"
        :activeColor="'primary'"
        class="w-64 bg-white"
        :placeHolder="$t('designation')"
      />
    </div>

    <div
      class="bg-white-dark rounded-lg mb-3  hover:border-grey-fontgrey"
      v-for="channel of GET_CUSTOMER_CHANNELS"
      :key="channel.id"
      :channel="channel"
      :class="
        GET_CUSTOMER_CHANNEL.id == channel.id
          ? 'border-t-4 border-primary'
          : 'border border-transparent'
      "
    >
      <channel-container
        v-if="GET_CUSTOMER_CHANNEL != null"
        :channel="channel"
        :editContainer="false"
        @editChannel="edit_channel(channel)"
      />
    </div>
  </bottom-up-modal>
</template>

<script>
import { computed, defineComponent, reactive } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import CustomButton from "../../../components/input/CustomButton.vue";
import InputText from "../../../components/input/InputText.vue";
import BottomUpModal from "../../../components/layout/BottomUpModal.vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import {
  CUSTOMER_CHANNELS_TYPES,
  HELPERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import ChannelContainer from "../components/ChannelContainer.vue";

export default defineComponent({
  components: {
    BottomUpModal,
    InputText,
    CustomButton,
    Searchbar,
    ChannelContainer,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeModal"],
  setup(props, ctx) {
    const store = useStore();
    const route = useRoute();

    /** State */
    const state = reactive({});

    /*
     * COMPUTEDS
     */
    const GET_CUSTOMER_CHANNEL = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_CHANNELS}/${CUSTOMER_CHANNELS_TYPES.GET_CUSTOMER_CHANNEL}`
      ];
    });

    const GET_CUSTOMER_CHANNELS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_CHANNELS}/${CUSTOMER_CHANNELS_TYPES.GET_CUSTOMER_CHANNELS}`
      ];
    });

    /*
     * FUNCTIONS
     */

    const SET_CUSTOMER_CHANNEL = (channel) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_CHANNELS}/${CUSTOMER_CHANNELS_TYPES.SET_CUSTOMER_CHANNEL}`,
        channel
      );
    };

    const UPDATE_CHANNEL = async () => {
      let channelData = {
        customer_id: GET_CUSTOMER_CHANNEL.value.customer_id,
        id: GET_CUSTOMER_CHANNEL.value.id,
        customer_channel: GET_CUSTOMER_CHANNEL.value,
      };

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "update"
      );
      const response = await store.dispatch(
        `${STORE_TYPES.CUSTOMER_CHANNELS}/${CUSTOMER_CHANNELS_TYPES.UPDATE_CUSTOMER_CHANNEL}`,
        channelData
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "update"
      );

      if (response.status == 200) {
        ctx.emit("closeModal");
      }
    };

    const edit_channel = (channel) => {
      SET_CUSTOMER_CHANNEL(channel);
    };

    const update_channel = (channel) => {
      SET_CUSTOMER_CHANNEL(channel);
      UPDATE_CHANNEL();
    };
    return {
      route,
      store,
      state,
      GET_CUSTOMER_CHANNEL,
      GET_CUSTOMER_CHANNELS,
      update_channel,
      edit_channel,
    };
  },
});
</script>
