<template>
  <div class="flex flex-col w-full" :style="`max-width: ${width}px`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 900,
    },
  },
};
</script>
